export default function Contact() {

    const createRipple = (y, x) => {
        let elementRipple = `<span class="circle" style="top:${y}px;left:${x}px;"></span>`;
        let wrapRipple = document.querySelector('.ripple');
        wrapRipple.innerHTML = elementRipple;
    }

    if (document.querySelector('.btn')) {
        document.querySelector('.btn').addEventListener('mousedown', e => {
            let offset = $(e.target).offset();
            createRipple(e.pageY - offset.top, e.pageX - offset.left);
        })
    }

    // ----

    $('.js-cpf').mask('000.000.000-00', { clearIfNotMatch: true });
    $('.js-zip-code').mask('00000-000', { clearIfNotMatch: true });

    const doAjaxSubmit = function(form) {
        var url = form.getAttribute('action'),
            formData = jQuery(form).serialize();
        form.classList.add('form--sending');

        jQuery.post(url + '?guid=' + (new Date()).getTime(), formData)
            .then(function(response) {
                //handle success
                form.classList.remove('form--sending');
                form.classList.add('form--done');
                if (response.status == 1) {
                    form.classList.remove('form--error');
                    form.classList.add('form--success');
                } else {
                    //Deu erro
                    form.classList.add('form--error');
                }
            })
            .catch(function(response) {
                //handle error
                console.log(response);
            });
    };

    const forms = document.querySelectorAll('form');
    Array.prototype.slice.call(forms)
        .forEach(function(form) {
            form.setAttribute('novalidate', '');
            form.addEventListener('submit', function(event) {
                event.preventDefault()
                event.stopPropagation()
                if (form.checkValidity()) {
                    doAjaxSubmit(form);
                }
                form.classList.add('was-validated')
            }, false)
        });

    $('#state').on('change', function(e) {
        var v = $(this).val(),
            u = 'cidades';

        $('#city').find('option:gt(0)').remove();

        $.get(u + '?uf=' + v, function(json) {
            var html = '';
            for (let index = 0; index < json.length; index++) {
                const element = json[index];
                html += '<option value="' + element.nome + '">' + element.nome + '</option>';
            }
            $('#city').append(html);
        });
    });

}